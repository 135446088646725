class Auth {
  constructor() {
    this.authenmticated = false;
  }

  login(cb) {
    this.authenmticated = true;
    cb();
  }

  logout(cb) {
    document.cookie = "email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    this.authenmticated = false;
    cb();
  }

  isAuthenticated = () => {
    function getCookie(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }

    function checkCookie() {
      var username = getCookie("email");
      if (username !== "") {
        return true;
      }
    }
    if (checkCookie()) {
      this.authenmticated = true;
      return this.authenmticated;
    }
  };
}

export default new Auth();
