import axios from "axios";
export const getURL = () => "https://transcription-api.humanelevation.com";
// export const getURL = () => "http://localhost:4000";

const url = getURL();

export const isBackwards = () => {
  const selection = window.getSelection();
  let range = document.createRange();
  range.setStart(selection.anchorNode, selection.anchorOffset);
  range.setEnd(selection.focusNode, selection.focusOffset);

  let backwards = range.collapsed;
  range.detach();
  return backwards;
};

export const highlight = (highlights) => {
  const container = document.getElementById("transcription-wrapper").children;
  for (let item of container) {
    if (item) item.classList.remove("mark");
  }
  for (let item of container) {
    const id = item.id;

    if (highlights.indexOf(id) > -1) {
      const el = document.getElementById(id);
      if (el) {
        el.classList.add("mark");
        el.nextSibling.classList.add("mark");
        el.previousSibling.classList.add("mark");
      }
    }
  }
};

export const updateDB = (id, data) => {
  axios({
    method: "POST",
    url: `${getURL}/update`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "content-type": "application/json",
    },
    data: { id, data },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("res", err);
    });
};

export const updateDBContent = (id, data, cb, setPristine) => {
  axios({
    method: "POST",
    url: `${url}/update-content`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "content-type": "application/json",
    },
    data: { id, data },
  })
    .then((res) => {
      setPristine(true);
      cb();
      return res.data;
    })
    .catch((err) => {
      console.log("res", err);
    });
};

export const updateDBTitle = (id, data) => {
  axios({
    method: "POST",
    url: `${url}/update-title`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "content-type": "application/json",
    },
    data: { id, data },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("res", err);
    });
};

export const updateDBLinebreaks = (id, data) => {
  axios({
    method: "POST",
    url: `${url}/update-linebreaks`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "content-type": "application/json",
    },
    data: { id, data },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("res", err);
    });
};

export const copy = () => {
  let text = window.getSelection().toString();
  try {
    let arr = text.split(":::");

    if (Array.isArray(arr)) {
      text = `${arr[0]}${
        document.getElementById("time-stamp-content").innerText
      }${arr[2]}`;
    }
  } catch (e) {}

  navigator.clipboard.writeText(text);
};

export const isElement = (obj) => {
  try {
    //Using W3 DOM2 (works for FF, Opera and Chrome)
    return obj instanceof HTMLElement;
  } catch (e) {
    //Browsers not supporting W3 DOM2 don't have HTMLElement and
    //an exception is thrown and we end up here. Testing some
    //properties that all elements have (works on IE7)
    return (
      typeof obj === "object" &&
      obj.nodeType === 1 &&
      typeof obj.style === "object" &&
      typeof obj.ownerDocument === "object"
    );
  }
};

export const authenticate = async (email, password) => {
  return axios({
    method: "POST",
    url: `${url}/login`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "content-type": "application/json",
    },
    data: { email, password },
  });
};

export const getUser = () => {
  let obj = {};
  document.cookie
    .split(" ")
    .map((item) => (obj = { [item.split("=")[0]]: item.split("=")[1] }));

  return obj.email;
};
