import React from "react";
import { Spinner } from "reactstrap";

const Loading = () => (
  <div className="loading">
    <div className="spinner-wrapper">
      <div>
        <span>Loading...</span>
        <Spinner size="sm" color="primary" />
      </div>
    </div>
  </div>
);

export default Loading;
