import React, { Component } from "react";
// import Navigation from "./components/Nav";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { authenticate } from "./helpers";
import Auth from "./helpers/auth";
import "./Login.css";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      error: "",
    };
  }

  authenticate = async (e) => {
    e.preventDefault();
    this.setState({
      error: "",
    });
    var date = new Date();
    date.setTime(date.getTime() + 30 * 1000);

    await authenticate(this.state.email, this.state.password)
      .then((res) => {
        if (res.data.email) {
          Auth.login(() => {
            var date = new Date();
            date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
            var expires = "; expires=" + date.toGMTString();
            document.cookie = "email=" + res.data.email + expires + "; path=/";

            this.props.history.push("/library");
          });
        } else {
          this.setState({
            error: "Error: Something is wroing with your credentials...",
          });
        }
      })
      .catch((err) => {
        console.log("res", err);
      });
  };
  render() {
    function getCookie(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }

    function checkCookie() {
      var username = getCookie("email");
      if (username !== "") {
        return true;
      }
    }
    if (checkCookie()) {
      this.props.history.push("/library");
    }
    return (
      <>
        {/* <Navigation history={this.props.history} /> */}
        <div className="container">
          <div className="col-md-3 offset-md-4 login-wrapper">
            <Form className="login-form" onSubmit={(e) => this.authenticate(e)}>
              <img
                className="he-logo"
                src="https://humanelevation.com/assets/img/human-elevation.png"
                alt="he-logo"
              />
              {this.state.error ? (
                <small className="error-msg">{this.state.error}</small>
              ) : (
                ""
              )}
              <FormGroup>
                <Label for="exampleEmail" hidden>
                  Email
                </Label>
                <Input
                  type="email"
                  name="email"
                  id="exampleEmail"
                  onChange={(e) =>
                    this.setState({ [e.target.name]: e.target.value })
                  }
                  placeholder="Email"
                />
              </FormGroup>{" "}
              <FormGroup>
                <Label for="examplePassword" hidden>
                  Password
                </Label>
                <Input
                  type="password"
                  name="password"
                  onChange={(e) =>
                    this.setState({ [e.target.name]: e.target.value })
                  }
                  id="examplePassword"
                  placeholder="Password"
                />
              </FormGroup>{" "}
              <Button
                className="login-btn"
                type="button"
                onClick={(e) => this.authenticate(e)}
              >
                Login
              </Button>
            </Form>
          </div>
        </div>
      </>
    );
  }
}
export default Login;
