import React from "react";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import Grid from "@material-ui/core/Grid";

const VideoSearch = ({ update, search, keyword, loading }) => (
  <>
    {/* <h1>Video library</h1> */}

    <Grid item xs={10} sm={8}>
      <TextField
        name="keyword"
        type="search"
        onChange={update}
        value={keyword}
        className="form-control"
        placeholder="keyword"
        aria-label="keyword"
        aria-describedby="button-addon2"
        fullWidth
        style={{ background: "transparent" }}
      />
    </Grid>
    <Grid item xs={2} sm={2}>
      <IconButton
        className="btn btn-info"
        type="button"
        id="button-addon2"
        onClick={search}
        disabled={loading}
        aria-label="search"
      >
        <SearchIcon />
      </IconButton>
    </Grid>
  </>
);

export default VideoSearch;
