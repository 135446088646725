import React from "react";
import "./nav.css";
import Auth from "../../helpers/auth";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
// import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

export const Navigation = ({ history, classes }) => {
  return (
    <>
      {/* <div className={classes.root}> */}
      <AppBar position="static" className={classes.blackBackground}>
        <Toolbar className={classes.toolbar}>
          {/* <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton> */}
          <Typography variant="button" className={classes.title}>
            ARGON
          </Typography>
          <Link to="/upload" className={classes.link}>
            Upload
          </Link>
          <Link to="/library" className={classes.link}>
            Library
          </Link>

          {Auth.isAuthenticated() && (
            <IconButton
              aria-label="Logout"
              className={classes.iconButton}
              onClick={() => {
                Auth.logout(() => {
                  history.push("/");
                });
              }}
            >
              <ExitToAppIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      {/* </div> */}
    </>
    // <Nav className="no-print">
    //   <NavItem>
    //     <Link to="/upload">upload</Link>
    //   </NavItem>
    //   <NavItem>
    //     <Link to="/library">Library</Link>
    //   </NavItem>

    //   {Auth.isAuthenticated() && (
    //     <NavItem style={{ position: "relative" }}>
    //       <button
    //         className="round-btn-inverted"
    //         onClick={() => {
    //           Auth.logout(() => {
    //             history.push("/");
    //           });
    //         }}
    //       >
    //         Logout
    //       </button>
    //     </NavItem>
    //   )}
    // </Nav>
  );
};
