// import { useAuth0 } from "./react-auth0-spa";
import React from "react";
import Player from "./Player";
import Upload from "./Upload";
import Login from "./Login";
import Library from "./components/Library";
import { Switch, Route } from "react-router-dom";
import { ProtectedRoute } from "./helpers/protected-route";
// import Auth from "./helpers/auth";
// import Profile from "./components/Profile";
// import PrivateRoute from "./components/PrivateRoute";
// import { fireEvent } from "@testing-library/react";

import Navigation from "./components/Nav";
import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },

  nav: {
    flexGrow: 1,
  },
  leftColumn: {
    color: theme.palette.common.white,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  rightColumn: {
    paddingTop: 20,
    color: theme.palette.common.white,
    height: "100%",
    overflow: "auto",
  },
  title: {
    flexGrow: 1,
    color: "#CCCCCC",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
      color: "white",
    },
  },
  link: {
    color: "#CCCCCC",
    textDecoration: "none",
    marginRight: 20,
    "&:hover": {
      textDecoration: "none",
      color: "white",
    },
  },
  blackBackground: {
    background: "#222222",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  iconButton: {
    color: "white",
    backgroundColor: "#222",
    "&:hover": {
      backgroundColor: "white",
      color: "#222",
    },
  },
}));

export default function App({ history }) {
  const classes = styles();
  return (
    <>
      <Navigation history={history} classes={classes} />
      <Switch>
        <ProtectedRoute exact path="/player/:id/:time?" component={Player} />
        <ProtectedRoute exact path="/upload" component={Upload} />
        <ProtectedRoute exact path="/library" component={Library} />
        <Route exact path="/" component={Login} />
        <Route path="*" componen={() => "404 NOT FOUND"} />
      </Switch>
    </>
  );
}
