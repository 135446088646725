import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "./library.css";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import VideoSearch from "../VideoSearch/videoSearch";
import Loading from "../Loading/loading";
import { getURL, getUser } from "../../helpers/index";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

const classes = (theme) => ({
  root: {
    height: "100%",
  },

  nav: {
    flexGrow: 1,
  },
  leftColumn: {
    color: theme.palette.common.white,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "2px 0.7px 7px rgba(0,0,0,0.1)",
    background: "#FCFCFC",
  },
  rightColumn: {
    paddingTop: 20,
    color: theme.palette.common.white,
    height: "100%",
    overflow: "auto",
  },
  title: {
    flexGrow: 1,
    color: "#CCCCCC",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
      color: "white",
    },
  },
  link: {
    color: "#CCCCCC",
    textDecoration: "none",
    marginRight: 20,
    "&:hover": {
      textDecoration: "none",
      color: "white",
    },
  },
  blackBackground: {
    background: "#222222",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  iconButton: {
    color: "white",
    backgroundColor: "#222",
    "&:hover": {
      backgroundColor: "white",
      color: "#222",
    },
  },
});

// var FA = require("react-fontawesome");
var dateFormat = require("dateformat");
const orderBy = require("lodash/_baseOrderBy");

const url = getURL();
class Library extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: "",
      videos: [],
      loading: true,
    };
  }

  getAllRecords = (e) => {
    if (e) e.preventDefault();

    //creating endpoint based on users
    // To filter the content
    let params = {
      user: getUser(),
    };
    // If is an admin show everything
    // If not an admin only show user's content
    if (
      getUser() === "dcj@humanelevation.com" ||
      getUser() === "ljg@humanelevation.com"
      // getUser() === "jmk@humanelevation.com"
    ) {
      params = {};
    }
    axios
      .get(`${url}/get-all`, { params })
      .then((videos) => {
        this.setState({ videos: [...videos.data] });
      })
      .then(() => {
        if (this.state.loading) this.setState({ loading: false });
      });
  };
  update = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };
  delete = (id, title) => {
    const d = () =>
      axios({
        method: "POST",
        url: `${url}/delete`,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "content-type": "application/json",
        },
        data: { id },
      })
        .then((res) => {
          this.setState({
            response: res.data,
          });
        })
        .catch((err) => {
          console.log("res", err);
          this.setState({
            error: "Error Occured while uploading the file",
            uploadSuccess: undefined,
          });
        });
    confirmAlert({
      title: "Confirm to Delete",
      message: `You are about to delete the record "${title}". Are you sure you want to proceed?`,
      buttons: [
        {
          label: "Yes",
          //   onClick: () => d()
          onClick: () => {
            d();
            return this.setState((prevState) => ({
              videos: prevState.videos.filter((el) => el.video_id !== id),
            }));
          },
        },
        {
          label: "No",
          onClick: () => false,
        },
      ],
    });
  };
  search = async (e) => {
    this.setState({ loading: true });

    e.preventDefault();
    let keyword = this.state.keyword || "";
    await axios.get(`${url}/get/${keyword}`).then((videos) => {
      console.log(videos);
      this.setState({ videos: [...videos.data] });
    });
    this.setState({ loading: false });
  };
  loadContent = (content = []) => {
    let str = [];

    content.map((item) =>
      item.elements
        ? item.elements.map((d) => {
            return str.push(d.value);
          })
        : ""
    );
    return str.join("");
  };
  componentWillMount() {
    this.getAllRecords();
  }
  render() {
    const { classes } = this.props;
    return (
      <div style={{ height: "100%" }}>
        {this.state.loading && <Loading />}

        <Grid container className={classes.root}>
          <Grid item sm={6} md={2} xs={12} className={classes.leftColumn}>
            {/* <form onSubmit={this.search}>
              <VideoSearch
                loading={this.state.loading}
                update={this.update}
                keyword={this.state.keyword}
                search={this.search}
              />

              <a className="search-link" href="#0" onClick={this.getAllRecords}>
                Get all records
              </a>
              <p className="records-number">
                <small>
                  Records found <strong>{this.state.videos.length}</strong>
                </small>
              </p>
            </form> */}

            <form style={{ width: "100%" }}>
              {/*  onSubmit={handleSubmit} */}
              <Container>
                <Grid>
                  <img
                    src="https://humanelevation.com/assets/img/human-elevation.png"
                    width={100}
                    alt="Logo"
                  />
                </Grid>
                <Grid>
                  <Typography
                    variant="h4"
                    component="h4"
                    style={{ color: "#222", textAlign: "left" }}
                  >
                    Search
                  </Typography>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  style={{ paddingTop: 20 }}
                >
                  <VideoSearch
                    loading={this.state.loading}
                    update={this.update}
                    keyword={this.state.keyword}
                    search={this.search}
                  />

                  <Grid
                    item
                    xs={12}
                    sm={10}
                    style={{ marginTop: 20, marginBottom: 20 }}
                  >
                    <p style={{ color: "#000" }}>
                      <small>
                        Records found{" "}
                        <strong>{this.state.videos.length}</strong>
                      </small>
                    </p>
                    <Link
                      to="/#0"
                      color="secondary"
                      underline="always"
                      onClick={this.getAllRecords}
                    >
                      View All
                    </Link>
                  </Grid>
                </Grid>
              </Container>
            </form>
          </Grid>
          <Grid item sm={6} md={10} xs={12} className={classes.rightColumn}>
            <List className={classes.root}>
              {orderBy(this.state.videos, ["date"], ["desc"]).map(
                (item, index) => (
                  <span key={`a-${index}`}>
                    <ListItem key={index}>
                      {/* <ListItemAvatar>
                      <Avatar>
                        <ImageIcon />
                      </Avatar>
                    </ListItemAvatar> */}
                      <Link
                        to={`/player/${item.video_id}`}
                        target="_blank"
                        style={{ textDecoration: "none", color: "#222" }}
                      >
                        <ListItemText
                          primary={item.video_title}
                          secondary={dateFormat(
                            new Date(item.date),
                            "dddd, mmmm dS, yyyy, h:MM:ss TT"
                          )}
                        />
                      </Link>
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() =>
                            this.delete(item.video_id, item.video_title, this)
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider component="li" />
                  </span>
                )
              )}
            </List>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(classes)(Library);
